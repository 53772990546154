import React, { useCallback, useEffect } from 'react';
import styles from './CancelAppointment.module.css';
import Imgs from 'Imgs/Imgs';

import { useCancelAppointmentStore } from './CancelAppointmentStore';
import { useTranslation } from 'react-i18next';
import { isBefore, isEqual, startOfDay } from 'date-fns';
import { classNames } from 'utils/lib/classNames/classNames';
import { CancelAppointmentModal } from '../CancelAppointmentModal/CancelAppointmentModal';
import {Button} from 'Components/BasicComponents/BasicComponents';
import PhoneNumberInput from 'Components/PhoneNumberInput/PhoneNumberInput';
import planguin from '../../../../Imgs/planguin.png';
import { useProfileBlockViewStore } from '../ProfileBlockView/ProfileBlockViewStore';
import {useBookingStore} from 'Pages/Booking/BookingStore';

function CancelAppointment(props) {
  const { t } = useTranslation();

  let {
    appointmentsAll,
    getSpecialistAppointments,
    cancelServiceWindowState,
    chooseServiceWindow,
    windowSection,
    changeWindowSection,
    showConfirmDeleteWindow,
    confirmDeleteWindow,
    confirmPhoneNumberWindow,
    handleConfirmPhone,
    confirmPhone
  } = useCancelAppointmentStore();

  const { specialistId } = useBookingStore();
  const { getSpecialistInfo, specialist } = useProfileBlockViewStore();

  const isCreatedAtTodayOrEarlier = (createdAt) => {
    const createdDate = new Date(createdAt);
    const today = startOfDay(new Date());

    const createdAtStartOfDay = startOfDay(createdDate);
    return isEqual(createdAtStartOfDay, today) || isBefore(today, createdAtStartOfDay)  ;
  };
  
  const appointmentsSorted = useCallback(() => {
    const groupService = [];
    const services = [];

    appointmentsAll.forEach(el => {
      const isValidDate = isCreatedAtTodayOrEarlier(el.createdAt ?? el.dateInRightFormat);
      if (!isValidDate) return;

      const isValidParticipant = el.groupServiceId
        ? el.participants.some((participant) => participant.phone === confirmPhone)
        : el.phone === confirmPhone;

      if (!isValidParticipant) return;

      if (el.groupServiceId) {
        groupService.push(el);
      } else {
        services.push(el);
      }
    });

    return { groupService, services };
  }, [appointmentsAll, confirmPhone]);

  useEffect(() => {
    const { groupService, services } = appointmentsSorted();
    
    if (!services.length) {
      changeWindowSection('groupService')
    }
    if (!groupService.length) {
      changeWindowSection('services')
    }
    
  }, [appointmentsSorted]);
   
  useEffect(() => {
    async function fetchAppointments() {
      if (specialist?._id) {
        await getSpecialistAppointments(specialist._id);
      }
    }

    if (chooseServiceWindow && !confirmDeleteWindow) {
      fetchAppointments();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chooseServiceWindow, confirmDeleteWindow, specialist]);

  useEffect(() => {
    async function af() {
      await getSpecialistInfo();
    }
    af();
  }, [specialistId]);

  if (confirmPhoneNumberWindow) {
    return (
      <>
        <div className={styles.popup_background}>
          <div style={{ width: '350px' }} className={styles.popup_window}>

            <div className={styles.title}>
              {t('close_service_1')}
              <span
                className={styles.close}
                onClick={() => {
                  cancelServiceWindowState(false);
                }}
              >
                <Imgs img={'close'} />
              </span>
            </div>
           
            <div className={styles.text}>
              Введіть номер телефону на який був зареєстрований запис👇🏻
            </div>

            <PhoneNumberInput
              id={'phone'} 
              handleChange={(value, country, event) => handleConfirmPhone(value)} 
            />

            <Button
              onClick={() => cancelServiceWindowState(true)}
              name={t('confirm')}
              color={'black'}
              style={{ marginTop: '30px' }}
            />
          </div>
        </div>
      </>
    );
  }

  if (chooseServiceWindow) {
      return (
        <>
        <CancelAppointmentModal />
          <div className={styles.popup_background}>
            <div className={styles.popup_window} style={{ width: '100%'}}>
              <div className={styles.title}>
                {t('close_service_1')}
                <span
                  className={styles.close}
                  onClick={() => {
                    cancelServiceWindowState(false);
                  }}
                >
                  <Imgs img={'close'} />
                </span>
              </div>

              <div className={styles.tabs_block}>
                Оберіть та видаліть запис 

                <div className={styles.tabs}>
                  <button onClick={() => changeWindowSection('services')} className={styles.tab}>Послуги</button>
                  <button onClick={() => changeWindowSection('groupService')} 
                    className={classNames(styles.tab, {[styles.serviceGroup]: windowSection === 'groupService'} ) }>Групи</button>
                </div>
              </div>

              <div className={styles.services_container}>
                {windowSection === 'services' && (<>
                  {!appointmentsSorted().services.length && (
                    <div className={styles.img_block}>
                      <div className={styles.img_title}>{t('appointments_no_found')}</div>
                      <div className={styles.img}>
                        <img src={planguin} alt="" />
                      </div>
                    </div>
                  )}
                  {appointmentsSorted().services.map((service) => {
                    const date = service.date.split('/');
                    const reverseDate = date[2] + '/' + date[1] + '/' + date[0];

                    return (
                      <div key={service._id} className={styles.serviceBlock}>
                        <span className={styles.date}>{reverseDate.replaceAll('/', '.')}</span>
                        <div className={styles.serviceInfo}>
                          <span>{service.timeRange}</span>
                          <span className={styles.serviceName}>{service.serviceName}</span>

                          <button 
                            className={styles.group_appointment_group__delete} 
                            type='button' 
                            onClick={() => {
                              showConfirmDeleteWindow(service);
                            }} 
                          >
                            <Imgs img={'delete'} />
                          </button>
                        </div>
                      </div>
                  )})}
                </>)
                }

                {windowSection === 'groupService' && <>
                  {!appointmentsSorted().groupService.length && (
                    <div className={styles.img_block}>
                      <div className={styles.img_title}>{t('appointments_no_found')}</div>
                      <div className={styles.img}>
                        <img src={planguin} alt="" />
                      </div>
                    </div>
                  )}
                  {appointmentsSorted().groupService.map((service) => {
                    const date = service.date.split('/');
                    const reverseDate = date[2] + '/' + date[1] + '/' + date[0];

                    return (
                    <div key={service._id} className={styles.serviceBlock}>
                       
                      <span className={styles.date}>{reverseDate.replaceAll('/', '.')}</span>
                      <div className={`${styles.serviceInfo} ${styles.serviceGroup}`}>
                        <span>{service.from}{} - {service.to}</span>
                        <span className={styles.serviceName}>{service.groupServiceName}</span>

                        <button 
                          className={styles.group_appointment_group__delete} 
                          type='button' 
                          onClick={() => {
                            showConfirmDeleteWindow(service);
                          }} 
                        >
                          <Imgs img={'delete'} />
                        </button>
                      </div>
                    </div>
                  )})}
                  </>
                }
                <div style={{ height: '80px' }}></div>
              </div>
            </div>
          </div>
        </>
      );
  }
}

export default CancelAppointment;
